body {
  text-align: center;

  color: #000000;
  overflow: hidden;
}

/*hggggggggggggggggggggggggggggggggggggggggggggggg*/
.Navbarmenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: 20px;
  color: #000000;
}
.Navbarmenu .Logoinfo {
  display: flex;
  flex-direction: row;
}
.Navbarmenu .Logoinfo p {
  align-self: center;
  font-weight: bold;
  font-size: larger;
}
.Navbarmenu .Logoinfo img {
  width: 60px;
  height: 60px;
}
.Navbarmenu .Notification {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  width: 60vw;
}
.Navbarmenu .Notification .input {
  align-self: center;
  display: flex;
  flex-direction: row;
  width: 700px;
}
.Navbarmenu .Notification .input input {
  width: 500px;
  height: 40px;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(218, 136, 136, 0.281) 0px 7px 29px 0px;
}
.Navbarmenu .Icons {
  display: flex;
  flex-direction: row;

  margin-left: 10px;
}
.Navbarmenu .Icons .Icon {
  margin-left: 25px;
  align-self: center;
}
.Sidemenu {
  margin-top: 10px;
  width: 290px;
  height: 89vh;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.281) 0px 7px 29px 0px;

  border-top-right-radius: 50px;
}
.Sidemenu .ProfileSidemenu img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin-top: 40px;
}
.Sidemenu .ProfileSidemenu h2 {
  margin: 0;
}
.Sidemenu .ProfileSidemenu p {
  margin: 0;
  color: #a3a3a3;
}

.Sidemenu .Buttons-menu {
  background-color: rgba(226, 226, 226, 0.575);
  border-radius: 10px;
  margin-inline: 50px;
  margin-top: 30px;
}
.Sidemenu .Buttons-menu .menus {
  padding-top: 10px;
  padding-bottom: 10px;
}
.Roomcardbox {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 200px;
  box-shadow: rgba(139, 139, 139, 0.37) 0px 7px 29px 0px;
  border-radius: 20px;
  overflow: hidden;
  margin: 5px;
}
.Roomcardbox {
  color: black;
}
.Sidemenu .Buttons-menu .menus .Menubutton {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 30px;
}
.inbox {
  width: 65vw;
  margin-left: 5vw;
  height: 87vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

.inbox-mobile {
  width: 95vw;
  margin-left: 2vw;
  height: 86vh;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.inputpages {
  margin: 5px;
  height: 79vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.sendmesssageMobile {
  display: flex;

  height: 30px;
  margin: 10px;
}
.sendmesssageMobile ::placeholder {
  color: rgb(255, 255, 255);
  padding-left: 10px;
}
.sendmesssageMobile input {
  width: 90%;
  height: 35px;
  color: white;
  background-color: rgba(0, 0, 0, 0.623);
  border-radius: 20px;
}
.sendmesssageMobile button {
  background-color: rgb(2, 21, 53);
  border: none;

  height: 40px;
  width: 40px;

  border-radius: 40px;
}
.Homechat {
  background-color: rgb(247, 247, 247);

  width: 95vw;
  margin-left: 2vw;
  height: 90vh;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
}
.messagebox-mobile {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  padding-right: 10px;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Discussebox-mobile {
  background-color: white;
  color: black;
  overflow-x: hidden;
  height: 90vh;
}
.sender {
  display: flex;
  flex-direction: row;
}
.profilepics img {
  height: 40px;
  width: 40px;
  border-radius: 25px;
}
.reciever {
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
}
#profilepics {
  align-self: flex-end;
}
.sendmesssageButton {
  display: flex;

  width: 100%;
  height: 30px;
}
.sendmesssageButtonmobile {
  display: flex;

  width: 100%;
  height: 30px;
}
.sendmesssageButton input {
  flex: 1;
  background-color: rgba(189, 183, 183, 0.5);
  border-radius: 20px;
  height: 30px;
}
.sendmesssageButton button {
  background-color: purple;
  color: white;
  border-radius: 70px;
  width: 35px;
  height: 35px;
  padding-top: 3px;
  padding-left: 3px;
}
.messagebox {
  width: 100%;

  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  padding-right: 10px;
  background-color: #f3f3f3;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.message {
  display: flex;
  justify-content: space-between;

  padding: 0px;
  border-radius: 7px;

  min-width: 50px;
  min-height: 30px;

  color: #ffffff;
  margin: 2px;
  padding: 3px;
}
#messagefont {
  align-self: center;
  font-size: 15px;
  max-width: 230px;
  word-wrap: break-word;
  color: white;
  text-align: justify;
}
.message p {
  font-size: 12px;
  padding: 5px;
  align-self: flex-end;
  color: rgb(146, 145, 145);
}
.inex input {
  width: 500px;
}
.Sidemenu .Buttons-menu .menus a {
  color: #4d4d4d;
}
.Menubutton:hover #menuicon {
  color: red;
}

.Sidemenu .Buttons-menu .menus .menuslink :hover {
  border: 2px solid yellow;
}

.Menubutton p {
  align-self: center;
}
#menuicon {
  align-self: center;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 40px;
}

.Menubutton :hover {
  cursor: pointer;
}
.inlinesProfilebody {
  display: flex;
  flex-direction: row;
}
.HomeBody {
  display: flex;
  flex-direction: column;
  width: 80vw;
  justify-content: flex-start;
  align-items: flex-start;
  height: 95vh;
  overflow: scroll;

  margin-left: 20px;
}
.HomebodyTitle h1 {
  margin: 0;
}
.HomebodyTitle p {
  color: rgb(204, 204, 204);
  margin: 0px;
}
.SELEctors-full {
  display: flex;
  flex-direction: row;
  margin-inline: 20px;
  font-weight: bold;
}
.Selector-part {
  display: flex;
  flex-direction: row;
  margin-right: 20vw;
}
.Selector-part p {
  margin-right: 25px;
}
.See-all-button p {
  color: white;
  background-color: #ff7b7f;
  height: 30px;
  width: 80px;
  border-radius: 20px;
}
.card-image {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgb(100, 100, 111) 0px 7px 29px 0px;

  margin-inline: 10px;
}
.card-image:hover {
  box-shadow: #112349 0px 7px 29px 0px;
}
.Card-top {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;

  margin-top: 20px;
  margin-inline: 20px;
}
.Card-top h4 {
  font-size: 18px;

  margin: 0px;
}
.Card-top p {
  font-size: 12px;
  margin: 0px;
}
.Card-bottom {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  margin-bottom: 20px;

  margin-inline: 20px;
}
.Card-bottom img {
  width: 40px;
  height: 40px;
  border-radius: 26px;
}
.Cardlist {
  margin-inline: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.ProgressTitle {
  margin-top: 10px;
}
.ProgressTitle h1 {
  margin: 0;
}
.ProgressTitle p {
  color: rgb(204, 204, 204);
  margin: 0px;
}
.Progress-cards {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin-bottom: 30px;
  width: 65vw;
}
.Progress-cards .Notdata {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.Notdata button {
  width: 130px;
  height: 40px;
  background-color: #bdb8b9;
  border: 2px solid transparent;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  font-weight: 100;
}
.Loading {
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}
.IsError {
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}
.IsError p {
  font-weight: bold;
}
.IsError img {
  width: 320px;
  height: 250px;
}
.IsError p {
  color: red;
}
.Small-Progress-card {
  margin-top: 10px;
}
.Smallprigressbarcard {
  box-shadow: rgba(0, 0, 0, 0.61) 0px 7px 29px 0px;
  background-color: white;
  width: 350px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: rgb(34, 33, 33);
}

.Smallprigressbarcard:hover {
  width: 380px;
  height: 120px;
  transition-delay: 1s;
}
.Smallprogressbar-image img {
  width: 80px;
  height: 80px;
  margin-left: 20px;
  border-radius: 20px;
}
.Smallprogressbar-title {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.Smallprogressbar-title p {
  margin: 0px;
}
.Smallprogressbar-title h4 {
  margin: 0px;
}
.Smallprogressbar {
  width: 80px;
  margin-right: 15px;

  margin-left: 20px;
}
.LargeProgressbar {
  background-color: white;
  box-shadow: rgba(69, 69, 77, 0.37) 0px 7px 29px 0px;
  display: flex;

  flex-direction: column;
  width: 300px;
  height: 350px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.LargeProgressTitle {
  display: flex;
  flex-direction: row;
}
.LargeprogressName {
  display: flex;
  flex-direction: column;
}
.LargeprogressName h4 {
  margin: 0px;
}
.LargeprogressName p {
  margin: 0px;
  color: rgb(99, 96, 96);
}
.LargeProgressImage img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.Largeprogressbars {
  width: 200px;
  margin-bottom: 0px;
}
.Largeprogressbars h4 {
  margin-top: -20px;
}

p {
  margin-top: 0px;
}
.searchbdetailbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 900px;
  height: 400px;
}
.Allcoursecards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  border-bottom: 1px solid rgb(177, 177, 177);
  margin-top: 5px;
}
.Allcoursecards .Allcoursecardbutton:hover {
  cursor: pointer;
  background-color: #808080;
}
.Allcoursecards .Allcoursecardimage img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}
.Allcoursecardtext h4 {
  margin: 0px;
  padding: 0px;
}
.Allcoursecardtext p {
  margin: 0px;
  margin-left: 8px;
  color: rgb(145, 142, 142);
}
.Allcoursecardtext {
  align-self: center;
}
.Allcoursecards .Allcoursecardbutton {
  align-self: center;
  background-color: rgb(34, 34, 34);
  width: 80px;
  border-radius: 10px;
  height: 40px;
  color: white;
}
.Allcoursecards .Allcoursecardbutton p {
  margin-top: 5px;
}
.Allcoursesbigcard {
  width: 300px;
  height: 350px;
  box-shadow: rgba(191, 191, 192, 0.37) 0px 3px 20px 0px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Allcoursesbigcard .coursepic {
  align-self: center;
}
.Allcoursesbigcard .coursepic img {
  width: 100px;
  height: 100px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.detailtext {
  text-align: start;
}
.detailtext h3,
p {
  margin: 0px;
}
.detailtext p {
  color: rgb(129, 129, 129);
}
.inline-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 270px;
  margin-top: 10px;
}
.Allcoursesbigcard .detailtext {
  margin-left: 10px;
}
.HomeBody .Pagenos {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.Pagenos .button {
  background-color: #0f0f0f;
  margin-right: 5px;
  width: 20px;
  height: 30px;
  border-radius: 3px;
}
.Pagenos p {
  padding-top: 5px;

  color: white;
}
.Gradecard {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 10px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.404);
}
.Gradecard .Gradecardimage img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.Gradecard .Gradecardname {
  align-self: center;
}
.Gradecard .Gradecardname h4 {
  margin: 0px;
}
.Gradecard .Gradecardname p {
  margin: 0px;
  color: rgb(179, 176, 176);
}
.Gradecard .Gradeeyebutton {
  align-self: center;
  display: flex;
  flex-direction: row;
  background-color: #000000;
  width: 90px;
  color: white;
  text-justify: center;
  border-radius: 20px;
  font-weight: bold;

  height: 30px;
}
.Gradecard .Gradeeyebutton p {
  margin-left: 20px;
}
.GradePercentage {
  width: 80px;

  align-self: center;
}
.Individualoutline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Individualoutline p {
  font-weight: bold;
}
.EnrollCard {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-between;
  width: 720px;
}
.EnrollCard .EnrollCard_image img {
  width: 400px;
  height: 400px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.Enrollcarddetail {
  margin-top: 20px;
  width: 300px;
}
.EnrollCard .Enrollcarddetail h1 {
  margin: 0px;
}
.EnrollCard .Enrollcarddetail p {
  color: rgb(116, 114, 114);
}
.EnrollCard .Enrollcarddetail .Courseoutline p {
  font-weight: bold;
  color: #000000;
  margin-left: 2px;
}
.Enrollcarddetail .Courseoutline {
  display: flex;
  flex-direction: row;
}
.enrollbutton {
  margin-top: 20px;
}
.enrollbutton button {
  width: 110px;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 10px;
}
#disabled {
  background-color: #51525123;
  color: #000000;
}
.HomebodyTitles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
#Select {
  width: 190px;
  margin-left: 20px;
}
.greetingbody {
  width: 100%;
  height: 100%;
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 70px;
}
.greetingbody .Headings {
  margin-left: 70px;
  align-self: flex-start;
}
.greetingbody .paragraph {
  width: 600px;
  align-self: flex-start;
  text-align: left;
}
.greetingbody .paragraph p {
  text-align: justify;
}
.greetingbody .Complete {
  align-self: flex-start;
}
.greetingbody .Video {
  align-self: flex-start;
  margin-top: 20px;
  margin-left: 20px;
}
.greetingbody .Image {
  align-self: flex-start;
}
.greetingbody .Image img {
  width: 540px;
  height: 250px;
}
.greetingbody .Codes {
  width: 540px;
}
.Buttons {
  margin-top: 30px;
  margin-left: 150px;
}
.Buttons button {
  width: 100px;
  height: 40px;
  background-color: #000000;
  color: white;
  font-weight: bold;
  border-radius: 7px;
}
.Quiz_get_started {
  align-self: center;
  margin-left: 190px;
  width: 500px;
}
.Quiz_get_started .Buttonstart {
  margin-top: -10px;
  margin-left: -130px;
}
.choice {
  display: flex;
  flex-direction: column;
}
.Answer {
  display: flex;
  flex-direction: row;
}
#mark {
  font-size: 25px;
  font-weight: bold;

  width: 500px;
  text-align: center;
  margin: 10px;
}
#quiz {
  text-align: justify;
}
.HomeBody .Eachquestion {
  background-color: rgb(248, 248, 248);
  margin-top: 10px;
  box-shadow: rgba(108, 108, 117, 0.212) 0px 3px 9px 0px;
  margin-left: 50px;
}
.Correction-true {
  width: 500px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  color: rgb(0, 226, 0);
}
.Correction-false {
  width: 500px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  color: rgb(221, 0, 0);
}
.Buttondiv {
  margin-top: -20px;
  margin-bottom: 30px;
}
.Loginbody .loginbox {
  box-shadow: 2px 2px 2px 1px rgba(47, 1, 250, 0.336);
  background-color: #ffffff;
  width: 100%;
}
.Loginbody .loginbox .inputboxs {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
}
.RightLoginbody {
  background-color: rgb(56, 51, 51);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RightLoginbody img {
  width: 500px;
  height: 600px;
}
.loginbox .inputboxs {
  height: 150px;
  margin-top: 70px;
}
.loginbox .inputboxs .Individualbox {
  display: inline-flex;
  margin-top: 5px;
}
.loginbox .inputboxs .Individualbox p {
  margin-top: 15px;
}
.inputboxs .Individualbox input {
  height: 30px;
  margin-top: 17px;
  margin-left: 10px;
  border-radius: 7px;
  background-color: #303131;
  color: white;
}
.inputboxs .button {
  margin-top: 30px;
  margin-left: -200px;
}
.inputboxs .button button:hover {
  background-color: #0b3a5a;
  cursor: pointer;
}
.inputboxs .button button {
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  border-radius: 5px;

  width: 110px;
  height: 40px;
}
.loginbox .header {
  margin-top: 90px;
  margin-left: -20px;
}
a {
  text-decoration: none;
  color: #e0dddd;
}
.Loginbody {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #0b3a5a;
}
.Notfond {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh*/

.Homepages {
  background-color: #f0f0f0;
  color: #080808;
}
/* width */

.app {
  padding-top: 0;
  margin: 0;
}
.Searchbox {
  padding-top: 10px;
}
.Searchbox input {
  background-color: #0d1117;
  color: white;

  border: 1px solid #30363d;
  height: 20px;
  padding-left: 10px;
}

.Sidebanner img {
  height: 90vh;
  width: 35vw;
  float: right;
  border-bottom-left-radius: 40px;
}

.navbar .leftsidenavbar img {
  margin-right: 20px;
  height: 50px;
  width: 50px;
  border-radius: 100px;
}

.profile img {
  height: 30px;
  width: 30px;
  border-radius: 100px;
}

.topic h3 {
  font-size: 35px;

  color: rgba(255, 217, 0, 0.959);
}
#teacherprofile p {
  margin-right: -30px;
}
.Button {
  background: linear-gradient(to right, #00c500 -42%, #19ff0563 100%);

  height: 32px;
  padding-top: 10px;
  width: 100px;

  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  margin-left: 10px;
}
.inlinebuttons p {
  margin-left: 25px;
  color: rgb(0, 162, 255);
}

/*kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkdddddddddjkjfkjfjf*/
.Mobile-navbar {
  display: flex;
  height: 50px;
  padding-left: 2%;
  padding-right: 2%;

  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(59, 57, 57, 0.185);
}
.Mobile-Homepages {
  display: flex;

  justify-content: flex-start;
  flex-direction: column;
}
.navbar-mobile-logo img {
  width: 50px;
  height: 50px;
}
.navbar-menu-button {
  align-self: center;
  font-size: 33px;
}
#name {
  align-self: flex-start;
  justify-self: center;
  border: none;
  margin-top: -10px;
  font-weight: normal;
  color: rgb(105, 105, 105);
}
.navbar-menu-Title {
  align-self: center;
  font-weight: bold;
}

.Mycourse-mobile {
  background-color: white;
  height: 86.9vh;
  display: flex;
  flex-direction: column;
}
.Mycourse-mobile-texts {
  color: rgb(99, 90, 90);
}
.Mycourse-mobile-nocontent-add {
  width: 70%;
  height: 20%;
  margin-left: 15%;
}
.Mycourse-mobile-nocontent-add {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(173, 173, 173, 0.274);
  margin-top: 5px;
}
.MobilecardList {
  height: fit-content;
  overflow-y: scroll;
}

.Mobile-course-progress {
  width: 75vw;
  height: 25vh;
  margin-left: 15%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(69, 69, 77, 0.37) 0px 2px 9px 0px;
  color: #000000;

  margin-bottom: 20px;
  border-radius: 12px;
}
.circularProgress {
  width: 130px;
}
.MobileprogresscardcourseTitle {
  font-weight: bold;
}
.Mobile-Homepages .navebarbody {
  display: flex;
  flex-direction: row;
}
.mobilebutton {
  color: white;
  font-weight: bold;
  background-color: #039103;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  width: 100px;
}
.MobileLearnBoard {
  background-color: white;
  height: 90vh;
  padding-top: 7px;
  margin-left: 2%;
  margin-right: 2%;
  overflow-y: scroll;
}

.Mobilecourseoutlinetitle {
  display: flex;

  color: rgb(17, 17, 17);
  justify-content: space-between;
  align-items: center;
}

.Mobilecourseoutlinetitle p {
  width: 200px;
  word-wrap: break-word;
  font-weight: bold;
}
.MobileLearncontent {
  margin-top: 20px;
  margin-bottom: 40px;
}
.Mobile-Video {
  margin-top: 10px;
}
.MobileLearncontentParagraph p {
  word-wrap: break-word;
  text-align: justify;
  margin-bottom: 10px;
}

.MobileLearncontentimage img {
  width: 100%;
  height: 200px;
}
.MobileLearncontentCodes {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 19px;
}
.Button-gotoquiz {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.MobileQuizboard {
  background-color: white;
  height: 90vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
}
.are-you-sure {
  width: 80vw;
  height: 190px;
  align-self: center;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.are-you-sure p {
  margin-bottom: 10px;
}
.QuizQuestions {
  width: 100vw;
  margin-bottom: 10vh;
}
#result {
  color: green;
  font-weight: bold;
  margin-top: 15px;
}
.mobile-each-questions {
  display: flex;
  margin-left: 4vw;
  margin-bottom: 20px;
  flex-direction: column;

  margin-right: 4vw;
}
.mobile-each-questions #Questions {
  font-weight: bold;
  width: 100%;
  text-align: left;

  word-wrap: break-word;
}

.Individual-ANswer {
  display: flex;
  flex-direction: row;
}
.Individual-ANswer p {
  word-wrap: break-word;
  width: 93%;

  text-align: justify;
}
.Mobile-Correction-true {
  height: 50px;
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(250, 250, 250);
  background-color: rgb(0, 182, 0);
  margin-top: 10px;
}
.Mobile-Correction-false {
  height: 50px;
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(250, 250, 250);
  background-color: rgb(233, 101, 101);
  margin-top: 10px;
}
.MobileButton-Submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MobileCourseList {
  background-color: white;
  height: 90vh;
  padding-top: 7px;
  margin-left: 2%;
  margin-right: 2%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MobileCourseCArds {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 10px;
}
.MobilePagenobutton {
  background-color: rgb(10, 10, 10);
  color: white;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 30px;
  margin-left: 10px;
}
.MobilePagenos {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.MobileCourseListText {
  margin-bottom: 10px;
}
.MobileListcards {
  margin-bottom: 5vh;
}
.MobileEnroll {
  background-color: white;
  height: 89vh;
  padding-top: 7px;

  margin-left: 2%;
  margin-right: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CourseTitle {
  display: flex;
  flex-direction: column;
}
.MobileCourseoutline {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
}

#Coutlineeourse {
  font-weight: bold;
  margin-bottom: 20px;
}
.courseoutlinesnames {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin-bottom: 5px;

  margin-top: 5px;
}
#circlenotch {
  margin-right: 10px;
  color: #00c500;
}
.MobileProfile {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.MobileProfile img {
  border-radius: 30px;
  width: 50px;
  height: 50px;
  margin-left: 5px;
}
@keyframes navbaranima {
  from {
    width: 0px;
  }
  to {
    width: 60vw;
  }
}
.Profilewithdarkmode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  height: 100px;
}
.Profilewithdarkmode .icons {
  align-self: start;
  padding: 10px;
}
.Profilewithdarkmode .Profileimage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-end;
}
.Profilewithdarkmode .Profileimage img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
/*#111111*/
.navbartab {
  position: absolute;
  background-color: #fafafa;

  box-shadow: rgba(108, 108, 117, 0.37) 0px 7px 29px 0px;

  border-radius: 10px;
  z-index: 1;
  width: 60vw;
  height: 90vh;
  right: 0px;
  top: 0px;
  padding-top: 20px;

  animation-name: navbaranima;
  animation-duration: 0.02s;
}

.navbartab p {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #000000;
  border-bottom: 1px solid black;
}
.MobilGradeReport {
  background-color: white;

  height: 89vh;
  padding-top: 7px;

  margin-left: 2%;
  margin-right: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MobileGradecardswithoutline {
  box-shadow: rgba(108, 108, 117, 0.37) 0px 7px 29px 0px;
  width: 95%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.Mobilegradecards {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  margin-top: 10px;
}
.Mobilegradecards img {
  width: 90px;
  height: 90px;
  border-radius: 10px;

  margin-bottom: 10px;

  align-self: center;
}
.Mobilegradecards p {
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-self: start;
  font-weight: bold;
  width: 200px;
}
#eyeicon {
  align-self: center;
}
.outline-grade {
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 10px;
  color: rgb(255, 255, 255);

  background-color: rgba(20, 17, 32, 0.822);
}
.outline-grade .Individualoutlineee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;

  align-items: center;
  height: 50px;
  margin-right: 5px;
  border-bottom: 1px solid gainsboro;
}
#tablename {
  font-weight: bold;
}
.Signupcomponent {
  height: 100vh;
  display: flex;
  background-color: rgb(5, 5, 5);
  color: white;
  flex-direction: column;
  align-items: center;
}
.Signupcomponentpc {
  background-color: rgb(243, 239, 239);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}
.Sign-upbox-pc {
  box-shadow: rgba(108, 108, 117, 0.37) 0px 7px 29px 0px;
  width: 27vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
}
.Sign-upbox {
  width: 97vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.MobileEachInput {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  width: 90%;
}
.MobileEachInput input:focus-within {
  outline: 2px solid rgb(7, 106, 255);
}
.Signupcomponent img {
  width: 150px;
  filter: invert(100);
}

.MobileEachInput input {
  width: 230px;
  height: 40px;
  border-radius: 5px;

  align-self: flex-end;
  border: 1px solid green !important;
}
.Start {
  width: 95vw;
  margin-top: 10px;

  display: flex;
  height: 40px;
  margin-right: 30px;

  justify-content: flex-end;
}
.Images {
  height: 50vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
}
.Start button {
  background-color: #00bfc5;
  border-radius: 60px;
  width: 100px;
}
.Showprofile-mobile {
  background-color: white;
  height: 86.9vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Showprofile-mobile img {
  width: 100px;
  height: 100px;
  border-radius: 60px;
}
.Showprofile-mobile p {
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(124, 120, 120);
  width: 100vw;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(194, 194, 194);
}
.MobileEachInput p {
  align-self: flex-start;
  margin-bottom: 10px;
  color: white;
}
.MobileEachInput input :focus {
  border: 2px solid rgba(133, 131, 128, 0.301);
}
.Mobilesignupbutton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  margin-top: 20px;
}
.Mobilesignupbutton p {
  margin-top: 10px;
}
#errors-signup {
  font-size: 12px;
  color: red;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  overflow: hidden;

  text-align: justify;
}

.create-indicator {
  background-color: #02e202;
  border-radius: 26px;
  color: rgb(247, 247, 247);
  height: 40px;
  width: 160px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Profilemobilepages {
  background-color: rgb(252, 252, 252);

  height: 90vh;
  padding-top: 7px;

  margin-left: 2%;
  margin-right: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ProfileImage {
  height: 140px;
  width: 140px;
}
.ProfileImage img {
  width: 100%;
  height: 100%;
}
.Notfond {
  background-color: white;
  width: 100vw;
  height: 100vh;
}
